import axios from "axios";
import store from "../../Redux/store";
import { HEADERS} from "../UrlAndPaths";
import { get_cash_inor_out_details, setCashInOrOutDialog } from "../../Redux/Dashboard/cashInOrOutSlice";
import { setSubmitLoading } from "../../Redux/Dashboard/mainDashBoardSlice";
import { errorSnackMsg, successSnackMsg } from "../../component/Custom Hooks/SnackkBarUtilities";


/// add cash in or out api cal
export const addCashInOrOutAPICall=(body,cancelFn)=>{
    store.dispatch(setSubmitLoading(true))
    axios.post("settings/addcashinorout",body,HEADERS)
    .then((res)=>{
        if(res.status===200){

            viewCashInOrCashOutAPICall()
            store.dispatch(setSubmitLoading(false))
            store.dispatch(setCashInOrOutDialog(false))
            successSnackMsg("Success")
            cancelFn()
        }
    })
    .catch((err)=>{
        store.dispatch(setSubmitLoading(false))
        errorSnackMsg(err.response.data)
    })
}

// view cash in or out api call
export const viewCashInOrCashOutAPICall=()=>{
    axios.post("settings/listcashinorout",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_cash_inor_out_details({cashInOrOutData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_cash_inor_out_details({cashInOrOutData:undefined}))
        console.log(err.response);
    })
}