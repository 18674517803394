import {
  Autocomplete,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import draft from "../../../../../Assets/statusRibbon/draft.png";
import post from "../../../../../Assets/statusRibbon/post.png";
import React, { useEffect, useRef, useState } from "react";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import returned from "../../../../../Assets/statusRibbon/return.png";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  vendorBillRegisterPaymentNewAPICall
} from "../API/vendorListAPICall";
import { journalListApiCall } from "../../../../../API/AccountUpdated/journalAPI";
import {
  addVendorBillNewApiCall,
  editVendorBillNewAPICall,
  generateVendorBillNameAPICall,
  postVendorBillNewAPICall,
  returnCompletedPaymentInVendorBill,
  returnVendorBillAPI,
  vendorAdvancePayAPI,
  vendorBillSingleViewApi,
} from "../API/vendorBillAPI";
import {
  findObjFromList,
  roundNumericFields,
  taxCalculationForLines,
} from "../../../../../Js/generalFunctions";
import { viewMultiCurrencyConfiguration } from "../../../../../API/Purchase Manager/configuration";
import { getAllTaxSettingsListAPICall } from "../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import {
  viewUOMSubListAPICall,
} from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";
import { getCurrentTime, today } from "../../../../../Js/Date";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import "../accountsVendor.css";
import paidRibbon from "../../../../../Assets/statusRibbon/paid.png";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import PaymentTerms from "../../../components/Dialog/paymentTerms/PaymentTerms";
import { generate_vendor_bill_name } from "../Slice/vendorBillSlice";
import Swal from "sweetalert2";
import { viewAllAllowedBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ReturnConfirmationDialog from "../components/ReturnConfirmationDialog";
import { TableVirtuoso } from "react-virtuoso";
import { errorSnackMsg } from "../../../../Custom Hooks/SnackkBarUtilities";

export const CreateVendorBills1 = () => {
  const buttonStyle = {
    backgroundColor: "#faedf5",
    borderRadius: "50%",
    border: "2px solid #d05aa5",
    height: "0.3em",
    width: "0.3em",
    "&:hover": {
      backgroundColor: "#faedf5",
    },
  };

  const iconStyle = {
    color: "#d05aa5",
    fontSize: "0.8rem",
  };

  let singleViewId = localStorage.getItem("singleViewById");
  const dispatch=useDispatch()

  const {branchDetails,currencyDetails} = useSelector((state) => state.mainDashBoardSlice)
  const vendorBillSingleData = useSelector(
    (state) => state.vendorBillSlice?.rowData
  );

  const numToWords = require("num-words");
  const generatedName = useSelector(
    (state) => state.vendorBillSlice.generateName
  );

  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  const decimalPosition = Number(localStorage.getItem("decimalPosition"));
  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );

  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  const controlAndExpenseAccList = chartOfAccountList?.filter(
    (item) => item.type === "Cost of revenue" || item.type === "Expenses"
  );
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
  const allActiveTaxList = allTaxList?.filter(
    (item) => item?.taxType === "Purchase"
  );

  const MultiCurrencyView = useSelector(
    (state) => state.ConfigurationSlice.MultiCurrency
  );

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const containerRef = useRef(null);
  const journalList = useSelector((state) => state.journalSlice.value);

  const journalSalesList = journalList?.filter(
    (item) => item?.type === "purchase"
  );
  const filterUomByPurchase = useSelector(
    (state) => state.unitOfMeasurmentSlice.filterByPurchaseList
  );

  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const customerList = useSelector(
    (state) => state.vendorsListSlice.apiResList
  );
  const paymentTermsList = useSelector(
    (state) => state.vendorsListSlice?.paymentTermsList
  );
  const addRowData = [ "Add Header",  "Add Line"];
  const [logView, setLogView] = useState(false);
  const [log, setLog] = useState([]);
  const [imageDisplay, setImageDisplay] = useState([]);
  const [isTableModified, setIsTableModified] = useState(false);
  const [registerPayment, setRegisterPayment] = useState(false);
  const [image, setImage] = useState([]);
  const [validationAlert, setValidationAlert] = useState({});
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState("Add Line");
  const [paymentTypeList, setPaymentTypeList] = useState([]);

  const [showPostBtn, setShowPostBtn] = useState(false); // show Post Btn state
  const [editClicked, setEditClicked] = useState(false);
  const [showEditBtn, setShowEditbtn] = useState(false); // show Edit btn

  const [showSaveBtn, setShowSaveBtn] = useState(true);
  const [disableinput, setDisableInput] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [handlePaymentTermsbtn, setHandlePaymentTermsbtn] = useState("");

  // const [editClickedId, setEditClickedId] = useState({});
  const [RegisterPaymentShow, setRegisterPaymentShow] = useState(false);

  const [totalsInfo, setTotalsInfo] = useState({
    subTotal: 0.0,
    inclusiveTax: 0.0,
    exclusiveTax: 0.0,
    total: 0.0,
    roundOff:0?.toFixed(decimalPosition)
  });

  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });

  let navigateTo = useNavigate();


  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };

  const formInitialState = {
    name: "###",
    vendor: null,
    branch: branchDetails,
    billReference: "",
    billDate: today,
    paymentReference: "",
    accountingDate: today,
    journal: findObjFromList(
      "_id",
      journalList,
      "purchaseJournal",
      configurationList?.purchase
    ),
    status: "",
    isGrnBill: false,
    currency: currencyDetails,
    product: null,
    label: "",
    account: null,
    qty: "",
    uom: "",
    unitPrice: "",
    taxes: null,
    discount: "",
    subTotal: "",
    expireDate: today,
    visibleOnly: false,
    itemTotal: 0,
    taxAmt: 0,
    customernotes: "",
    termsandconditions: "",
    paymentTerms: null,
    lpoNo:"",
    registerPaymentDate: today,
    paymentType: null,
    type: "Manual",
    chequeNo: "",
    registerPaymentChequeDate: "",
    registerPaymentAmount: "",
    paymentsDone: [],
    isEdit: false,
    paymentStatus: false,
  };
  const [formValues, setFormValues] = useState(formInitialState);

  const [tableData, setTableData] = useState([
    {
      type: "lines",
      id: Date.now(),
      product: null,
      label: "",
      account: null,
      uom: null,
      qty: "",
      unitPrice: "",
      discount: "",
      tax: [],
      amount: "",
      inclusive: "",
    },
  ]);

  const [filteredTaxOptions, setFilteredTaxOptions] = useState(
    tableData?.map(() => allActiveTaxList || [])
  );

  const [rowIndex, setRowIndex] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);

  const formData = new FormData();
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    const keyCode = e.keyCode || e.which;
    if (key === "registerPaymentAmount") {
      if (vendorBillSingleData?.amountDue > value) {
        setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
      } else {
        setFormValues({
          ...formValues,
          [key]: value !== 0 ? vendorBillSingleData?.amountDue : newValue,
        });
      }
    } else if (keyCode === 13) {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]: newValue !== null ? newValue : null,
      });
    } else {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]:
          value !== 0 && value !== undefined
            ? value
            : newValue !== null
            ? newValue
            : null,
      });
    }
  };

  const removeImageFn = (i) => {
    image.splice(i, 1);
    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
  };
  const [addRowScroll, setAddRowScroll] = useState(false);
  //addRow
  const addRow = () => {
    if (addRowInput === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), value: "" },
      ]);
    } else if (addRowInput === "Add Line") {
      let lastRow=tableData[tableData.length-1]
      if(lastRow?.account===null || lastRow?.label===""){
        errorSnackMsg("Fill all the rquired fields in the last row !!")
      }else{
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product: null,
          label: "",
          account: null,
          uom: null,
          qty: "",
          unitPrice: "",
          discount: "",
          tax: [],
          amount: "",
          inclusive: "",
        },
      ]);}
    }

    // setAddRowInput(null);
    setAddRowScroll(!addRowScroll);
  };

  //handleInputChange
  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData?.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          setRowIndex(index);
          let customerTax=newValue?.vendorTax || []
          let taxObjects=allTaxList?.filter(obj => customerTax.includes(obj._id))

          let selectedTaxType=null
          if(taxObjects.length!==0){
            selectedTaxType=taxObjects[0]?.includedPrice
          }  
          const newFilteredTaxOptions =selectedTaxType !== null? allActiveTaxList?.filter((option) => option.includedPrice === selectedTaxType)
              : allActiveTaxList;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            [field]: newValue,
            label: newValue?.productName || "",
            account: findObjFromList(
              "_id",
              chartOfAccountList,
              "purchaseControlAccount",
              configurationList?.purchase
            ),
            uom: findObjFromList("_id", uomSubList, "purchaseuom", newValue),
            qty: newValue !== null ? 1 : "",
            unitPrice: newValue?.cost || "",
            discount: newValue !== null ? 0 : "",
            tax:taxObjects,
            amount:
            newValue !== null ? newValue?.cost : "",
            inclusive: selectedTaxType !== null ? selectedTaxType : "",
          };
        } else if (field === "tax") {
          const selectedTaxId =
            newValue?.length !== 0 ? newValue[0]?.includedPrice : undefined;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allActiveTaxList.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allActiveTaxList;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            tax: newValue,
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "qty") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.unitPrice);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "unitPrice") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.qty);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "discount") {
          let totalAmt = 0;
          let totalItemAmt = Number(row?.qty) * Number(row?.unitPrice);
          let discount =
            (Number(value)?.toFixed(decimalPosition) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else {
          return { ...row, [field]: newValue !== undefined ? newValue : e.target.value };
        }
      }
      return row;
    });

    calculateTax(updatedData)

  };
 // tax calculated from general function
  const calculateTax = (array) => {
    let taxUpdatedTable = taxCalculationForLines(array)
    setTableData(taxUpdatedTable)
  }
  const clickLogView = () => {
    setLogView(!logView);
  };

  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setImageDisplay([...imageDisplay, URL.createObjectURL(e.target.files[0])]);
  };

  //handleDelete
  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };

  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };
  const backButtonFunction = () => {
    if (formValues?.isEdit === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        localStorage.removeItem("singleViewById");
        navigateTo("/userdashboard/accounts/vendor/vendorBills");
      }
    } else {
      localStorage.removeItem("singleViewById");
      navigateTo("/userdashboard/accounts/vendor/vendorBills");
    }
  };

  //handleMouseEnter
  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, [
      "qty",
      "unitPrice",
      "discount",
      "amount",
      "recievedQuantity",
    ]);
    setTableData(updatedArray);
  };

  //closeRegistePayment
  const closeRegistePayment = () => {
    setRegisterPayment(false);
  };

  //SaveBtnFun
  const SaveBtnFun = (key) => () => {
    dispatch( setFilterActive(false))
    //formData
    formData.append("journelId", formValues?.journal?._id);
    formData.append("vendorId", formValues?.vendor?._id);
    formData.append("branchId", formValues?.branch?._id);
    formData.append("billreference", formValues?.billReference);
    formData.append("LpoNo", formValues?.lpoNo);
    formData.append("billDate", formValues?.billDate);
    formData.append("paymentReference", formValues?.paymentReference);
    formData.append("accountingDate", formValues?.accountingDate);
    formData.append("currency", formValues?.currency?._id); // currency feild ??
    // formData.append("paymentTerms", formValues?.paymentTerms?._id)
    formValues?.paymentTerms !== null && formData.append("paymentTerms", formValues?.paymentTerms?._id)
    formData.append("customerNote", formValues?.customernotes);
    formData.append("termsAndCondition", formValues?.termsandconditions);
    formData.append(
      "otherInfo",
      JSON.stringify({ expiryDate: formValues?.expireDate })
    ); // stringfy

    formData.append(
      "tax",
      Number(totalsInfo?.inclusiveTax?.toFixed(decimalPosition)) +
        Number(totalsInfo?.exclusiveTax?.toFixed(decimalPosition))
    );
    formData.append("total", totalsInfo?.total?.toFixed(decimalPosition));
    formData.append("roundOff",Number(totalsInfo?.roundOff)?.toFixed(decimalPosition));
    formData.append("amountDue", 0);
    formData.append("postDate", `${today} ${getCurrentTime()}`);
    image.forEach((file, index) => {
      formData.append("file", file);
    });

    const productInfo = tableData?.filter((obj) => obj.product !== null).map((item) => {
      if (item.type === "header") {
        return {
          line_type: item.type,
          label: item?.value,
        };
      } else {
        return {
          line_type: item.type,
          _id: item.product?._id,
          mainCatgeoryId: item?.product?.mainCatgeoryId || item?.mainCatgeoryId,
          product: item?.product?.productName,
          prodType: item?.product?.prodType,
          label: item?.label,
          uomName: item?.product?.uomName || item?.uomName,
          uom: item?.product?.uom || item?.uom,
          purchaseUomName: item?.uom?.uomName,
          purchaseuom: item?.uom?._id,
          qty: Number(item?.qty),
          accountId: item?.account?._id,
          unitPrice: Number(item?.unitPrice),
          cost: "",
          discount: Number(item?.discount),
          includedPrice: item?.tax[0] ? item?.tax[0]?.includedPrice : null,
          taxes: item?.taxSplitted,
          taxIdList:item?.taxIdList,
          lineTotal: Number(item?.amount),
        };
      }
    });

    formData.append("itemInfo", JSON.stringify(productInfo));

    // draft
    if (key === "draft") {
      if (
        formValues?.vendor?._id === null ||
        formValues?.vendor?._id === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          vendor: "fill this field !!",
        });
      } 
      else if (
        formValues.billReference === ""
      ) {
        setValidationAlert({
          ...validationAlert,
          billReference: "fill this field !!",
        });
      } 
      else if (
        formValues.branch?.storeCode === null ||
        formValues.branch?.storeCode === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          branch: "fill this field !!",
        });
      } else if (
        formValues.billDate === null ||
        formValues.billDate === undefined ||
        formValues.billDate === ""
      ) {
        setValidationAlert({
          ...validationAlert,
          billDate: "fill this field !!",
        });
      } else if (
        formValues?.accountingDate === null ||
        formValues?.accountingDate === undefined ||
        formValues?.accountingDate === ""
      ) {
        setValidationAlert({
          ...validationAlert,
          accountingDate: "fill this field !!",
        });
      } else if (
        formValues?.journal === null ||
        formValues?.journal === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          journal: "fill this field !!",
        });
      } else if (
        formValues?.currency === null ||
        formValues?.currency === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          currency: "fill this field !!",
        });
      } else {
        let draftFn = () => {
          setFormValues({ ...formValues, status: "DRAFT", isEdit: true });
        };
        addVendorBillNewApiCall(
          formData,
          setSnackBarStates,
          snackBarStates,
          setShowPostBtn,
          setShowEditbtn,
          setDisableInput,
          draftFn,
          setLoadingForm
        );
      }
    }
    if (key === 'return') {
      returnVendorBillAPI({
        id: singleViewId,
        postDate: `${today} ${getCurrentTime()}`
      }, setAlertPopup, setSnackBarStates)
    }

    // edit
    if (key === "edit") {
      formData.append("id", singleViewId);

      let editFn = () => {
        setFormValues({ ...formValues, isEdit: true });
      };

      editVendorBillNewAPICall(
        formData,
        setSnackBarStates,
        snackBarStates,
        setEditClicked,
        setShowPostBtn,
        setDisableInput,
        editFn,
        setLoadingForm
      );
    }
    //post
    if (key === "post") {
      let postFn = () => {
        setFormValues({ ...formValues, status: "COMPLETED", isEdit: true });
      };
      // formData.append("_id", vendorBillSingleData?._id);
      formData.append("_id", singleViewId);
      postVendorBillNewAPICall(
        formData,
        setSnackBarStates,
        snackBarStates,
        setRegisterPaymentShow,
        setShowPostBtn,
        setShowEditbtn,
        singleViewId,
        postFn,
        setDisableInput,
        setLoadingForm
      );
    }
    if (key === "registerPaymentPost") {
      formData.append("vendorBillId", singleViewId);

      formData.append("paymentTypeId", formValues?.paymentType?._id);
      formData.append("chequeDate", formValues?.registerPaymentChequeDate);
      formData.append("chequeNo", formValues?.chequeNo);
      formData.append(
        "type",
        formValues.type == "Manual" ? 1 : formValues.type == "Cheque" ? 2 : ""
      ); //type
      formData.append("totalAmount", formValues?.registerPaymentAmount); //
      formData.append("paymentDate", formValues?.registerPaymentDate);
      formData.append(
        "amountInWords",
        numToWords(Math.floor(formValues?.registerPaymentAmount))
      );

      //checking
      const fieldsToCheck = [
        "registerPaymentDate",
        "paymentType",
        "type",
        "chequeNo",
        "registerPaymentChequeDate",
        "registerPaymentAmount",
      ];
      const isFieldEmpty = (field) =>
        field === null || field === undefined || field === "";
      if (singleViewId) {
        let allFieldsValid = true;
        for (const fieldName of fieldsToCheck) {
          if (
            (fieldName === "registerPaymentChequeDate" &&
              formValues.type === "Cheque") ||
            fieldName !== "registerPaymentChequeDate"
          ) {
            if (isFieldEmpty(formValues[fieldName])) {
              setValidationAlert({
                ...validationAlert,
                [fieldName]: "fill this field !!",
              });
              allFieldsValid = false;
              break;
            }
          }
        }

        if (allFieldsValid) {
          let sendObj = {
            vendorBillId: singleViewId,
            paymentTypeId: formValues?.paymentType?._id,
            chequeDate: formValues?.registerPaymentChequeDate,
            chequeNo: formValues?.chequeNo,
            type:
              formValues.type == "Manual"
                ? 1
                : formValues.type == "Cheque"
                ? 2
                : "",
            totalAmount: formValues?.registerPaymentAmount,
            paymentDate: formValues?.registerPaymentDate,
            amountInWords: numToWords(
              Math.floor(formValues?.registerPaymentAmount)
            ),
            postDate: `${today} ${getCurrentTime()}`,
          };

          vendorBillRegisterPaymentNewAPICall(
            sendObj,
            setSnackBarStates,
            snackBarStates,
            closeRegistePayment,
            singleViewId
          );
        }
      }
    }
  };
  const editBtnFun = () => {
    setEditClicked(true);
    setDisableInput(false);
    setShowPostBtn(false);
    setFormValues({ ...formValues, isEdit: false });
  };

  //clearRegisterForm
  const clearRegisterForm = () => {
    setFormValues({
      ...formValues,
      registerPaymentDate: "",
      paymentType: null,
      type: null,
      chequeNo: "",
      registerPaymentChequeDate: "",
      registerPaymentAmount: "",
    });
  };

  const registerPymentCancelBtn = () => {
    setRegisterPayment(false);
    clearRegisterForm();
  };

  const roundOffOnChange=(e)=>{
    const {value}=e.target
    let roundOffLimit=configurationList?.roundingLimit
    if(value==="-" ||Number(value) >= -(roundOffLimit) && Number(value) <= (roundOffLimit)){
      setTotalsInfo({...totalsInfo,roundOff:value})
    }else{
      errorSnackMsg(`Maximum allowed round off is :${roundOffLimit}`)
    }
  }

  // advance payments api call
  const paymentAdvanceClick=(list)=>()=>{

    let requestBody={
      case: 1,
      paymentId: list?._id,
      billId: singleViewId
    }

    let postFn=()=>{
      vendorBillSingleViewApi(
        { _id: singleViewId },
        setDisableInput,
        setShowSaveBtn
      )
    }
    Swal.fire({
      text: "Amount will be redeemed from vendor !!",
      icon: 'warning',
      confirmButtonText: 'Add',
      showCancelButton: true,
      customClass:{
          cancelButton:'sweet-alert-cancel-button',
          confirmButton:'sweet-alert-submit-button',
          }
      }).then((result)=>{
          if(result.isConfirmed){
            vendorAdvancePayAPI(requestBody,postFn)
          }
      })

      
  }
  useEffect(() => {
    if (vendorBillSingleData !== undefined && allBranchesList!==undefined) {
      setLog(vendorBillSingleData?.log || []);
      setTotalsInfo({...totalsInfo,roundOff:vendorBillSingleData?.rounding?.toFixed(decimalPosition) || 0?.toFixed(decimalPosition)})
      setFormValues({
        ...formValues,
        vendor:vendorBillSingleData?.vendorAddress,
        branch: findObjFromList(
          "_id",
          allBranchesList,
          "branchId",
          vendorBillSingleData
        ),
        billReference: vendorBillSingleData?.billreference || "",
        lpoNo: vendorBillSingleData?.LpoNo || "",
        billDate: vendorBillSingleData?.billDate || "",
        paymentReference: vendorBillSingleData?.paymentReference || "",
        // accountingDate: vendorBillSingleData?.accountingDate,
        journal: findObjFromList(
          "_id",
          journalList,
          "journelId",
          vendorBillSingleData
        ),
        currency: findObjFromList(
          "_id",
          currencyListUpdated,
          "currency",
          vendorBillSingleData
        ),
        name: vendorBillSingleData?.name,
        customernotes: vendorBillSingleData?.customerNote || "",
        termsandconditions: vendorBillSingleData?.termsAndCondition || "",
        expireDate: vendorBillSingleData?.dueDate || "",
        paymentsDone: vendorBillSingleData?.paymentsDone,
        currencyName: vendorBillSingleData?.currencyName,
        status: vendorBillSingleData?.status,
        paymentStatus: vendorBillSingleData?.paymentStatus,
        currencyDetails: vendorBillSingleData?.currencyDetails,
        amountDue: vendorBillSingleData?.amountDue,
        isEdit: true,
        paymentStatus: vendorBillSingleData?.paymentStatus,
        registerPaymentAmount: vendorBillSingleData?.amountDue||0,
        paymentTerms:findObjFromList(
          "_id",
          paymentTermsList,
          "paymentTerms",
          vendorBillSingleData
        )
      });
      dispatch(generate_vendor_bill_name({ name: vendorBillSingleData?.name }));

      setShowEditbtn(formValues.status === "DRAFT" ? true : false);
      setShowSaveBtn(formValues.status === "" ? true : false);
      setDisableInput(formValues.status === "DRAFT" ? true : false);
      setShowPostBtn(formValues.status === "DRAFT" ? true : false);
    } else {
      setFormValues(formInitialState);
    }
  }, [
    vendorBillSingleData,
    chartOfAccountList,
    companyProfileData,
    currencyListUpdated,
    allBranchesList
  ]);
  useEffect(() => {
    if (vendorBillSingleData?.itemInfo !== undefined && allNewProductList!==undefined && allTaxList!==undefined) {
      const updatedArray = vendorBillSingleData?.itemInfo?.map((r, i) => {
        if (r?.line_type === "Line" || r?.line_type === "lines") {

          let taxesId=r?.taxIdList || []
          let taxObjects = allTaxList?.filter(obj => taxesId.some(taxObj => taxObj._id === obj?._id));
          const newFilteredTaxOptions=r?.includedPrice !== null? allActiveTaxList?.filter((option) => option.includedPrice === r?.includedPrice)
              : allActiveTaxList;
          filteredTaxOptions[i] = newFilteredTaxOptions;

          return {
            type: r?.line_type,
            product:r?.prodObj || null,
            label: r?.label,
            account: findObjFromList(
              "_id",
              chartOfAccountList,
              "accountId",
              r
            ),
            uom: findObjFromList("_id", uomSubList, "purchaseuom", r),
            qty: r?.qty,
            unitPrice: r?.unitPrice,
            discount: r?.discount,
            tax: taxObjects,
            amount:
               r?.qty * r?.unitPrice -
              ((r?.qty) * r?.unitPrice * r?.discount) /
              100,
            inclusive: r?.includedPrice,
          };
        } else {
          return {
            type: r?.line_type,
            header: r?.label,
          };
        }
      });
      calculateTax(updatedArray);
    }
  }, [vendorBillSingleData?.itemInfo,allNewProductList,allTaxList]);

  //default journalName
  useEffect(() => {
    if (vendorBillSingleData === undefined  && configurationList !== undefined) {
      setFormValues({
        ...formValues,
        journal: findObjFromList(
          "_id",
          journalList,
          "purchaseJournal",
          configurationList?.purchase
        ),
      });
    }
  }, [configurationList,journalList]);


  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollIntoView({index:tableData.length,behavior:"smooth",align:"end",behaviour:"smooth"});
      }, 0);
    }
  }, [addRowScroll]);

  useEffect(() => {
    userRole==="user"&& viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });

    journalListApiCall();
    viewUOMSubListAPICall();
    getAllTaxSettingsListAPICall();
    viewMultiCurrencyConfiguration();
    chartOfAccountListApiCall();
    if (singleViewId) {
      vendorBillSingleViewApi(
        { _id: singleViewId },
        setDisableInput,
        setShowSaveBtn
      );
    }
  }, []);

  useEffect(() => {

    let subTotal=tableData?.reduce((sum, item) => sum + Number(item?.amount || 0),0)
    let inclusiveTax=tableData?.reduce((sum, item) => sum + Number(item?.inclusiveTax || 0),0)
    let exclusiveTax=tableData?.reduce((sum, item) => sum + Number(item?.exclusiveTax || 0),0)
    let roundedValue=Number(totalsInfo.roundOff)?.toFixed(decimalPosition)
    setTotalsInfo({
      ...totalsInfo,
      subTotal:subTotal,
      inclusiveTax:inclusiveTax,
      exclusiveTax:exclusiveTax,
      total:subTotal+exclusiveTax+Number(roundedValue)
    });

  }, [tableData,totalsInfo.roundOff]);

  // generateVendorBillName
  useEffect(() => {
    if (
      formValues.branch !== null &&
      formValues.journal !== null &&
      formValues.billDate !== ""
    ) {
      singleViewId === null &&
        generateVendorBillNameAPICall({
          branchId: formValues.branch?._id,
          journelId: formValues.journal?._id,
          billDate: formValues.billDate,
        });
    } else {
      setFormValues({ ...formValues, name: "###" });
    }
  }, [formValues.branch, formValues.journal, formValues.billDate]);



  // filter payment type
  useEffect(() => {
    if (journalList !== undefined) {
      let stockValuationAccList = journalList?.filter(
        (item) => item.type === "bank" || item.type === "cash"
      );
      setPaymentTypeList(stockValuationAccList);
    }
  }, [journalList]);

  // Amount due
  useEffect(() => {
    if(registerPayment){
      setFormValues({
        ...formValues,
        // registerPaymentAmount: totalsInfo?.total?.toFixed(decimalPosition),
        registerPaymentAmount:Number( formValues?.registerPaymentAmount)?.toFixed(decimalPosition),
      });
    }
  }, [registerPayment]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formValues?.isEdit]);

const [open, setOpen] = useState(false);
  const [tempData, setTempData] = useState(null);

  const handleClickOpen = (r) => {
    setTempData(r);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (tempData) {
      returnCompletedPaymentInVendorBill({
        body: {
          paymentId: tempData?.paymentId,
          billId: tempData?.billId,
          paymentObId: tempData?._id,
          postDate: `${today} ${getCurrentTime()}`,
        },
        _id: singleViewId,
        clearTemp: setTempData,
        dialog: setOpen,
        index:1
      });
    }
  };

  return (
    <div className="global-page-parent-container">
      
      <div
        className="new-global-white-bg-container"
        style={{padding: "0 0", backgroundColor: "unset" }}
      >
        <div className="new-global-white-bg-icon-container" style={{backgroundColor:"white"}}>
          <div className="new-global-white-bg-icon-container-left" style={{display: "flex", alignItems: "center" }}>
            {/* <Tooltip title="Back">
              <IconButton onClick={backButtonFunction}>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip> */}

            {formValues?.status === "DRAFT" && (
              <Tooltip title="Edit">
                <IconButton>
                  {editClicked ? (
                    <i
                      class="bi bi-floppy save-icon"
                      style={{ fontSize: "1.5rem" }}
                      onClick={SaveBtnFun("edit")}
                    ></i>
                  ) : (
                    <i
                      class="bi bi-pencil-square edit-icon1"
                      onClick={editBtnFun}
                    ></i>
                  )}
                </IconButton>
              </Tooltip>
            )}

            {formValues?.status === "" && (
              <Tooltip title="Save">
                <IconButton onMouseEnter={handleMouseEnter}>
                  <i
                    class="bi bi-floppy save-icon"
                    onClick={SaveBtnFun("draft")}
                  ></i>
                </IconButton>
              </Tooltip>
            )}

            {formValues?.status === "DRAFT" && !editClicked && (
              <>
              <button className="btn btn-post" onClick={SaveBtnFun("post")}>
                Post
              </button>
              <button
                  className="btn btn-primary"
                  style={{
                    backgroundColor:"#eb4034",
                    height: "32px",
                    width: "15%",
                  }}
                  onClick={() => setAlertPopup(true)}
                >
                  Return
                </button>
              </>
            )}

            {formValues?.status === "COMPLETED" &&
              !formValues?.paymentStatus && (
                <button
                  onClick={() => setRegisterPayment(true)}
                  className="btn btn-post"
                >
                  Register Payment
                </button>
              )}
        <h4 className="global-name">
        <span
                style={{ fontSize: "medium",  color: "#676666", cursor: "pointer" }}
                onClick={backButtonFunction}
              >
                Vendor Bill &gt;
              </span>
        <span style={{ fontSize: "medium", paddingLeft: "8px",color:"black" }}> {generatedName}</span>
        </h4>

          </div>

          {/* <div className="status-container">
            <p>Draft</p>
          </div> */}
          
        </div>
        {/* <hr className="global-hr" /> */}
        <div style={{backgroundColor:"white",padding:"10px",marginTop:"6px",position:"relative"}}>

        <div className="status-container" style={{right:"0"}}>
            {formValues?.status === "DRAFT" && <img src={draft} alt="" />}
            {formValues?.status === "COMPLETED" && <img src={post} alt="" />}
            {formValues?.status === "RETURNED" && (
              <img src={returned} alt="" />
            )}
            {formValues?.status === "PAID" &&
              formValues?.paymentStatus === true && <img src={paidRibbon} />}
          </div>
        <h4 className="global-name">{generatedName || "###"}</h4>
        <div className="new-global-input-container">
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerList || []}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendor*"
                  focused
                />
              )}
              value={formValues?.vendor}
              onChange={getFormInfo("vendor")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.vendor}</p>
          </div>
          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={userRole==="admin" ? allBranchesList: allowedBranchList || []}

              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Branch*" focused />
              )}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={formValues?.branch}
              onChange={getFormInfo("branch")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>

          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Bill Reference*"
              variant="outlined"
              type="text"
              focused
              value={formValues?.billReference}
              onChange={getFormInfo("billReference")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.billReference}</p>

          </div>

          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Bill Date*"
              variant="outlined"
              type="date"
              focused
              value={formValues.billDate}
              onChange={getFormInfo("billDate")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.billDate}</p>
          </div>

          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Payment Reference*"
              variant="outlined"
              type="text"
              focused
              value={formValues.paymentReference}
              onChange={getFormInfo("paymentReference")}
              disabled={formValues?.isEdit}
            />
          </div>

          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={journalSalesList || []}
              getOptionLabel={(option) => option?.journalName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Journal*" focused />
              )}
              value={formValues?.journal}
              onChange={getFormInfo("journal")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.journal}</p>
          </div>

          <div className="new-global-single-input">
            {MultiCurrencyView !== undefined &&
            MultiCurrencyView?.isMultiCurrency ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={currencyListUpdated || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Currency*" focused />
                )}
                value={formValues?.currency}
                onChange={getFormInfo("currency")}
                // disabled={formValues?.isEdit}
                disabled={formValues?.isEdit}
              />
            ) : (
              <TextField
                value={formValues?.currency?.name || ""}
                sx={{ caretColor: "transparent" }}
                className="disabled-input"
                id="outlined-basic"
                label="Currency*"
                variant="outlined"
                type="text"
                focused
                title="Multi Currency not enabled"
                // disabled={formValues?.isEdit}
                disabled={formValues?.isEdit}
              />
            )}
            <p className="doc-validation-alert">{validationAlert?.currency}</p>
          </div>
          <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "22%" }}
            >
              <div className="" style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={paymentTermsList || []}
                  getOptionLabel={(option) => option?.paymentTerm}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Terms*" focused />
                  )}
                  value={formValues?.paymentTerms || null}
                  onChange={getFormInfo("paymentTerms")}
                  disabled={formValues?.isEdit}
                />
                {!formValues.isEdit && (
                  <button
                    onClick={() => {
                      setFormValues((prev) => ({
                        ...prev,
                        paymentTerms: null,
                      }));
                      setHandlePaymentTermsbtn(!handlePaymentTermsbtn);
                    }}
                    className="add-row-btn autocomplete-inside-plusbtn"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.lpoNo}
                onChange={getFormInfo("lpoNo")}
                id="outlined-basic"
                label="LPO No."
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
        </div>
      </div>
      <div className="new-global-white-bg-container">
        <div
          className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
          style={{ marginBottom: "1%" }}
        >
          <div
            className="tab-menu-button active"
            id="productMenuBtn"
            onClick={productMenuClick}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Invoice Lines
            </Typography>
          </div>
          <div
            className="tab-menu-button"
            id="departmentMenuBtn"
            onClick={departmentMenuClick}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Other Info
            </Typography>
          </div>
        </div>
        {isActiveTable === "invoiceLines" ? (
          <>
            <TableVirtuoso
              ref={containerRef}
              className="global-product-table"
                    style={{ height: tableData?.length === 1 ? "10vh" : tableData?.length < 5 ? `${(tableData?.length) * 7}vh` : "25vh", maxHeight: "35vh" }}
                    data={tableData}
                    fixedHeaderContent={() => (
                      <tr>
                      <th>Product</th>
                      <th>Label</th>
                      <th>Account</th>
                      <th>UOM</th>
                      <th>Qty</th>
                      <th>UnitPrice</th>
                      <th>Discount</th>
                      <th>Tax</th>
                      <th>Amount</th>
                    </tr>
                    )}
                    itemContent={(index, item) => (
                      <>
                      {item.type === "header" ? (
                        <>
                          <td colSpan="9">
                            <input
                              type="text"
                              onChange={(e) =>
                                handleInputChange(index, "value")
                              }
                            />
                          </td>
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{ color: "#db0000", fontSize: "1.3rem" }}
                              ></i>
                            </IconButton>
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ minWidth: "150px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={allNewProductList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option)=>option?.productName}
                                renderOption={(props, option) => (
                                  <li {...props} key={option._id}>
                                    <span>{option.productName}</span>
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                )}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                disabled={formValues?.isEdit}
                                value={item?.product}
                                onChange={handleInputChange(index, "product")}
                              />
                            </div>
                          </td>
                          <td style={{  width:`${
                                    String(item?.label).length * 10
                                  }px`,minWidth:"90px" }}>
                            <input
                              type="text"
                              onChange={handleInputChange(index, "label")}
                              value={item?.label}
                              disabled={formValues?.isEdit}
                            />
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={controlAndExpenseAccList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.accountName}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={formValues?.isEdit}
                                value={item?.account}
                                onChange={handleInputChange(index, "account")}
                              />
                            </div>
                          </td>
                          <td style={{ minWidth: "50px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                  options={uomSubList?.filter((obj)=>obj?.parentCatId === (item?.product?.parentUomId)) || []}
                                  className="uom-autocomplete"
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.uomName}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                value={item?.uom}
                                disabled={formValues?.isEdit}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                onChange={handleInputChange(index, "uom")}
                              />
                            </div>
                          </td>
                          <td style={{ width:`${
                                    String(item?.qty).length * 9
                                  }px`,minWidth:"40px"}}>
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              onChange={handleInputChange(index, "qty")}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled={formValues?.isEdit}
                              onFocus={(e)=>e.target.select()}
                              value={item?.qty}
                            />
                          </td>
                          <td style={{ maxWidth: "85px" }}>
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              onChange={handleInputChange(index, "unitPrice")}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              value={item?.unitPrice}
                              onFocus={(e)=>e.target.select()}
                              disabled={formValues?.isEdit}
                            />
                          </td>
                          <td style={{ width: "30px" }}>
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              onChange={handleInputChange(index, "discount")}
                              value={item?.discount}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              onFocus={(e)=>e.target.select()}
                              disabled={formValues?.isEdit}
                            />
                          </td>
                          <td style={{maxWidth:"245px",minWidth:item?.tax.length===0?"90px":item?.tax.length===1?"200px":`265px`}}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                multiple
                                className="multiple-tax-autocomplete"
                                options={filteredTaxOptions[index] || []}
                                getOptionLabel={(option) => option?.taxName}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                value={item?.tax}
                                disabled={formValues?.isEdit}
                                onChange={handleInputChange(index, "tax")}
                              />
                            </div>
                          </td>
                          <td style={{ width:`${
                                    String(item?.amount).length * 9
                                  }px`,minWidth:"40px"}}>
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              readOnly
                              value={item?.amount}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled={formValues?.isEdit}
                            />
                          </td>
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                              disabled={formValues?.isEdit}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{ color: "#db0000", fontSize: "1.3rem" }}
                              ></i>
                            </IconButton>
                          </td>
                        </>
                      )}
                    </>
                    )}
              />
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete"
                style={{ width: "76%" }}
              >
                <Autocomplete
                  options={addRowData || []}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} />}
                  value={addRowInput}
                  disabled={formValues?.isEdit}
                  onChange={(e, newValue) => setAddRowInput(newValue)}
                />
              </div>
              <button
                onClick={() => addRow()}
                className="add-row-btn"
                disabled={formValues?.isEdit}
              >
                +
              </button>
            </div>
          </>
        ) : (
          <div
            className="new-global-input-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Expire Date*"
                variant="outlined"
                type="date"
                focused
                value={formValues.expireDate}
                disabled={formValues?.isEdit}
                onChange={getFormInfo("expireDate")}
              />
            </div>

            <button
              className="btn btn-primary"
              style={{ backgroundColor: "#4e4444" }}
              onClick={() =>
                navigateTo(
                  "/userdashboard/accounts/accounting/journalEntriesCreate"
                )
              }
            >
              Journal Entry
            </button>
          </div>
        )}
      </div>

      {isActiveTable === "invoiceLines" && (
        <div
          className="new-global-white-bg-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "8px 2%",
          }}
        >
          <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
          <div className="new-global-single-input" style={{ width: "35%" }}>
            <p>Customer Notes</p>
            <textarea
              name=""
              id=""
              cols="50"
              rows="5"
              value={formValues?.customernotes}
              disabled={formValues?.isEdit}
              onChange={getFormInfo("customernotes")}
            ></textarea>
          </div>

          <div className="new-global-single-input" style={{ width: "35%" }}>
            <p>Terms & Conditions</p>
            <textarea
              name=""
              id=""
              cols="50"
              rows="5"
              value={formValues?.termsandconditions}
              disabled={formValues?.isEdit}
              onChange={getFormInfo("termsandconditions")}
            ></textarea>
          </div>

          <div
            className="new-global-single-input"
            style={{ maxWidth: "250px" }}
            onMouseLeave={()=>setTotalsInfo({...totalsInfo,roundOff:Number(totalsInfo?.roundOff)?.toFixed(decimalPosition)})}
          >
            <div className="subtotal-ctn">
              <div className="subtotal-ctn-left">
                <p>Sub Total</p>
                {totalsInfo?.inclusiveTax !== 0 && <p>Inclusive Tax</p>}

                {totalsInfo?.exclusiveTax !== 0 && <p>Exclusive Tax</p>}
                {totalsInfo?.exclusiveTax === 0 &&
                  totalsInfo?.inclusiveTax === 0 && <p>Tax</p>}
              </div>
              <div className="subtotal-ctn-right">
                <p>{totalsInfo?.subTotal?.toFixed(decimalPosition)}</p>
                {totalsInfo?.inclusiveTax !== 0 && (
                  <p>{totalsInfo?.inclusiveTax?.toFixed(decimalPosition)}</p>
                )}
                {totalsInfo?.exclusiveTax !== 0 && (
                  <p>{totalsInfo?.exclusiveTax?.toFixed(decimalPosition)}</p>
                )}
                {totalsInfo?.exclusiveTax === 0 &&
                  totalsInfo?.inclusiveTax === 0 && <p>0.00</p>}
              </div>
            </div>
            <hr
              className="global-hr"
              style={{ width: "80%", marginLeft: "25%" }}
            />
            <div className="subtotal-ctn">
              <div className="subtotal-ctn-left">
                <p style={{ color: "#000", fontWeight: "600" }}>Total</p>
                {
                     configurationList?.isRoundingEnabled &&
                     <p >Round Off</p>}
              </div>
              <div className="subtotal-ctn-right">
                <p>{totalsInfo?.total?.toFixed(decimalPosition)} </p>
                {
                      configurationList?.isRoundingEnabled &&  
                      <input 
                      type="number"
                      onFocus={(e)=>e.target.select()}
                      value={totalsInfo?.roundOff}
                      disabled={formValues?.isEdit}
                      onChange={roundOffOnChange}
                      onMouseLeave={()=>setTotalsInfo({...totalsInfo,roundOff:Number(totalsInfo?.roundOff)?.toFixed(decimalPosition)})}
                      
                      />}
              </div>
            </div>

            
          </div>

          
        </div>
        <div style={{ width: "100%", display: "flex" }}>
          
          <div
            style={{ display: "flex", flexDirection: "column", width: "75%" }}
          >
            <div className="file-picker-new">
              <input type="file" id="fileUpload" onChange={changeImage} />
              <label htmlFor="fileUpload">
                <img src={uploadFile} alt="" />
                Upload File
              </label>
            </div>

            <div style={{ display: "flex", margin: "0 0 0 22px" }}>
              {imageDisplay?.map((r, i) => (
                <div className="uploaded-image-list">
                  <img src={r} alt="" />
                  <div className="image-remove-icon-new">
                    <IconButton onClick={() => removeImageFn(i)}>
                      <CloseOutlined
                        sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {vendorBillSingleData !== undefined && (
                  <div className="payment-info-amounts-ctn">
                    {formValues?.paymentsDone?.map((r, i) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // padding: "1em",
                        }}
                      >
                        <p
                          style={{
                            margin: "0",
                            fontSize: "0.8rem",
                            color: "rgb(111 109 109)",
                          }}
                        >
                          {r?.name}
                        </p>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "0.8rem",
                            color: "#000",
                            fontWeight: 600,
                          }}
                        >
                          {formValues?.currencyDetails?.symbol}
                          {r?.totalAmount?.toFixed(decimalPosition)}
                        </p>
                       {r?.paymentMode ==="ADVANCE" && <div>
                      <IconButton
                        onClick={() => handleClickOpen(r)}
                        style={buttonStyle}
                      >
                        <KeyboardReturnIcon style={iconStyle} />
                      </IconButton>
                    </div>}
                      </div>
                    ))}
                    {
                    formValues?.status === "COMPLETED" &&
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          color: "rgb(111 109 109)",
                        }}
                      >
                        Amount Due
                      </p>
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          color: "#000",
                          fontWeight: 600,
                        }}
                      >
                        {formValues?.currencyDetails?.symbol}
                        {formValues?.amountDue?.toFixed(decimalPosition)}
                      </p>
                    </div>}
                    {
                    formValues?.status === "COMPLETED" &&
                    vendorBillSingleData?.advancePayments && vendorBillSingleData?.advancePayments.length!==0 && formValues?.amountDue>0 &&
                    <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          fontWeight:"600",
                          borderBottom:"1px solid rgb(182,182,182)"
                        }}
                      >
                        Advance Payments
                      </p>
                    </div>

                    {
                      vendorBillSingleData?.advancePayments?.map((list,i)=>(
                        <div
                        key={i}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          color: "rgb(111 109 109)",
                        }}
                      >
                        {list?.name}
                      </p>
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          color: "#000",
                          fontWeight: 600,
                        }}
                      >
                        {list?.advBalanceAmt?.toFixed(decimalPosition)}
                      </p>

                      <button 
                      onClick={paymentAdvanceClick(list)}
                      className="advance-payments-add-btn justify-center">Add</button>
                    </div>
                      ))
                    }
                    </>
                    }
                  </div>
                )}
        </div>
        </div>
      )}
{/* log */}
      <div
        className="new-global-white-bg-container new-log-container"
        style={{ padding: "4px" }}
      >
        <div
          style={{ padding: "8px", cursor: "pointer" }}
          onClick={clickLogView}
        ></div>
        <div
          className={logView ? "pointer-up" : "pointer"}
          onClick={clickLogView}
          style={{ cursor: "pointer" }}
        ></div>
        {logView && (
          <div className="single-log-container">
            {log?.map((r, i) => (
              <div className="single-log-item">
                <div className="single-log-item-line-image">
                  <hr className="single-log-item-line" />
                  <div className="single-log-item-image">
                    <img src={logoImage} alt="" />
                  </div>
                  <hr className="single-log-item-line" />
                </div>
                <p className="heading">{r?.status}</p>
                <p className="name">{r?.empId}</p>
                <p className="date-time">{r?.date}</p>
                <p className="date-time">{r?.time}</p>
                
              </div>
            ))}
          </div>
        )}

      </div>
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
 <Dialog
        open={alertPopup}
        onClose={() => {
          alertPopup();
        }}
        maxWidth="lg"
      >
        <div style={{ textAlign: "center", paddingTop: "24px" }}>
          {" "}
          <ReportProblemOutlinedIcon
            sx={{ color: " #d50808", fontSize: "xx-large" }}
          />
        </div>
        <div
          className="shift-end-button-password-container"
          style={{ width: "25vw", padding: "2% 4%" }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "2% 0",
              alignSelf: "center",
              paddingBottom: "14px",
            }}
          >
            Are you sure you want to return
          </div>
          <hr
            style={{
              backgroundColor: "#bfbfc3",
              width: "100%",
              height: "2px",
              marginBottom: "27px",
            }}
          ></hr>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{ height: "28px", width: "28%" }}
              className="btn btn-primary"
              onClick={SaveBtnFun("return")}
            >
              Yes
            </button>
            <button
              onClick={() => setAlertPopup(false)}
              style={{ height: "28px", width: "28%" }}
              className="btn btn-secondary-outlined"
            >
              No
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog 
          maxWidth="md" open={registerPayment} onClose={() => setRegisterPayment(false)}>
        <div className="payment-popup">
          <h4>Register Payment</h4>
          <hr className="global-hr" />

          <div  className="global-input-field-container">
            <div
              className="new-global-single-input"
              style={{ width: "47%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Date*"
                variant="outlined"
                type="date"
                focused
                value={formValues?.registerPaymentDate}
                onChange={getFormInfo("registerPaymentDate")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.registerPaymentDate}
              </p>
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "45%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={paymentTypeList || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Type*" focused />
                )}
                value={formValues?.paymentType}
                onChange={getFormInfo("paymentType")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentType}
              </p>
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "47%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["Manual", "Cheque"]}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => (
                    <TextField {...params} label="Type*" focused />
                )}
                value={formValues?.type}
                onChange={getFormInfo("type")}
              />
              <p className="doc-validation-alert">{validationAlert?.type}</p>
            </div>
            <div
              className="new-global-single-input"
              style={{ width: "45%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label={
                  formValues?.type === "Cheque"
                    ? "Cheque No."
                    : "Transaction No"
                }
                variant="outlined"
                type="text"
                focused
                value={formValues?.chequeNo}
                onChange={getFormInfo("chequeNo")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.chequeNo}
              </p>
            </div>
            {formValues?.type === "Cheque" && (
              <>
                <div
                  className="new-global-single-input"
                  style={{ width: "47%", padding: "6px" }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Cheque Date*"
                    variant="outlined"
                    type="date"
                    focused
                    value={formValues?.registerPaymentChequeDate}
                    onChange={getFormInfo("registerPaymentChequeDate")}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.registerPaymentChequeDate}
                  </p>
                </div>
              </>
            )}

            <div
              className="new-global-single-input"
              style={{ width: "47%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Amount*"
                variant="outlined"
                type="number"
                focused
                value={formValues?.registerPaymentAmount}
                onChange={getFormInfo("registerPaymentAmount")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.registerPaymentAmount}
              </p>
            </div>
          </div>
          <hr className="global-hr" />
          <div>
            <button
              className="btn btn-primary"
              onClick={SaveBtnFun("registerPaymentPost")}
            >
              Post
            </button>
            <button
              className="btn btn-secondary-outlined"
              onClick={registerPymentCancelBtn}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <PaymentTerms handleDialog={handlePaymentTermsbtn} setHandleDialog={setHandlePaymentTermsbtn} />
      <LoadingForm loading={loadingForm} />
      <ReturnConfirmationDialog
        dialogOpen={open}
        dialogCloseFun={handleClose}
        confirmBtnFunc={handleConfirm}
      />
      </div>
    </div>
  );
};
