import { createSlice } from "@reduxjs/toolkit";

export const categorySlice=createSlice({
    name:"categorySlice",
    initialState:{
        value:undefined,
        categorySingleView:undefined,
        tableList:undefined,
        subCategoryList:[]
    },
    reducers:{
        get_all_category:(state,action)=>{
            state.value=action.payload.categoryData;
        },
        singleViewCategory:(state,action)=>{
            state.categorySingleView = action.payload.singleViewCategoryList;
        }
        ,
        category_table_list:(state,action)=>{
            state.tableList = action.payload.tableCategoryList
        },
        set_sub_category_list:(state,action)=>{
            state.subCategoryList = action.payload;
        }
    }
})
export const {get_all_category,singleViewCategory,category_table_list,set_sub_category_list}=categorySlice?.actions
export default categorySlice.reducer