import { Typography } from '@mui/material'
import React from 'react'
import {  useSelector } from 'react-redux';

const LoginForm = ({onChangeFn, handleNextClick,clickLoginBtn}) => {
 
  const { companyCode,userName,password } = useSelector((state) => state.loginFormNewSlice || {});
  const userRole = useSelector((state) => state.searchUserSlice.value);

  const isNextEnabled=!(userName===null || userName==="" || companyCode===null || companyCode==="")
  return (
    <>
  
    <div className='login-new-main-left-container'>
     <div className='login-form-container'>
      <div className="login-text-container" style={{placeItems:"center"}}>
        <Typography fontSize={"2rem"} fontWeight={700} marginLeft={"24px"} padding={"27px"}className="login-text">
          Login
        </Typography>
        <Typography fontSize={".90rem"}  fontWeight={600} letterSpacing={1.4} marginLeft={"24px"} className="login-description">
          Accounting & Sales Innovative ERP Solutions.
        </Typography>
      </div>
      <div className="login-new-form-container">
        <div className="login-form" >
          <div className="user-name-container">
            <p>Company Code</p>
            <div className="login-new-username-wrapper">
              <input
                className="right-input-login-user"
                type={"text"}
                name="companyCode"
                id="companyCodeInput"
                value={companyCode}
                onChange={onChangeFn}
              />
            </div>
           
            <p>User Name</p>
            <div className="login-new-username-wrapper">
              <input
                className="right-input-login-user"
                type={"text"}
                name="userName"
                id="companyCodeInput"
                value={userName}
                onChange={onChangeFn}
                onKeyDown={(e) => {
                  e.key === "Enter" && handleNextClick();
                }}
              />
            </div>
            {userRole && (
                <div style={{position:"relative"}}>
                  <p>Password</p>
                  <div className="login-new-username-wrapper">
                    <input
                      className="right-input-login-user"
                      type="password"
                      name="password"
                      id="passwordInput"
                      value={password}
                      onChange={onChangeFn}
                      onKeyDown={(e) => {
                        e.key === "Enter" && clickLoginBtn();
                      }}
                    />
                  </div>

                  <button
                    type="submit"
                    // className="login-btn btn-hide"
                    id="loginSubmitBtn"
                    className={password ? "login-new-btn" : "login-btn-disabled"} 
                    disabled={password === "" ? true : false}
                    onClick={clickLoginBtn}
                  >
                    Login
                  </button>
                </div>
              )}
          </div>

          {!userRole && 
             <button
                disabled={!isNextEnabled}
                type="button"
                className={isNextEnabled ? "login-new-btn" : "login-btn-disabled"} 
                id="userNameNextBtn"
                onClick={handleNextClick}
              >
                Next
              </button>
        }

          <div className="login-new-content-wrapper-forgot-password-register">
            <a className="login-register-new-link" target="_blank" href="https://eyeterp.com/" style={{color:"#000",fontSize:".90rem"}}>Register here !</a>
            <p style={{fontSize:".90rem"}}> Forgot password &gt;&gt;&gt;</p>
          </div>
        </div>
      </div>
      </div>
    </div>

   
    </>
  )
}

export default LoginForm