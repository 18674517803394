import React, { useState } from 'react';
import Pagelayout from '../../components/Pagelayout';
import {
  Grid,
  TextField,
  Autocomplete,
  FormControlLabel,
  Switch,
  Button,
  Box,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const CreatePayHead = () => {
  // Options for dropdowns
  const categoryOptions = ['BASIC', 'ALLOWANCE', 'DEDUCTION', 'COMMISSION', 'BONUS'];
  const calculationTypeOptions = ['FIXED AMOUNT', '% OF BASIC', '% OF CTC'];
  const expenseAccountOptions = ['Salaries a/c', 'Wages a/c'];
  const loanAccountOptions = ['Loan a/c', 'Advance a/c'];

  // Initial state
  const [formData, setFormData] = useState({
    category: '',
    name: '',
    // includeInBasic: false,
    isLoanDeduction: '',
    calculationType: '',
    percentageOfBasic: '',
    percentageOfCTC: '',
    amount: '',
    fixedAmount: '',
    expenseAccount: '',
    loanAccount: ''
  });

  // Error state
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleChange = (field) => (event, newValue) => {
    setFormData({
      ...formData,
      [field]: event?.target?.type === 'checkbox' ? event.target.checked : (newValue || event.target.value)
    });
    // Clear error when user types
    if (errors[field]) {
      setErrors({ ...errors, [field]: '' });
    }
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ['category', 'name', 'calculationType', 'expenseAccount'];
    
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = 'This field is required';
      }
    });

    // Validate amount fields based on calculation type
    if (formData.calculationType === 'FIXED AMOUNT' && !formData.fixedAmount) {
      newErrors.fixedAmount = 'Fixed amount is required';
    }
    if (formData.calculationType === '% OF BASIC' && !formData.percentageOfBasic) {
      newErrors.percentageOfBasic = 'Percentage of basic is required';
    }
    if (formData.calculationType === '% OF CTC' && !formData.percentageOfCTC) {
      newErrors.percentageOfCTC = 'Percentage of CTC is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const navigate = useNavigate();
  // Handle save
  const handleSave = () => {
    if (validateForm()) {
      console.log('Form data:', formData);
      // Add your save logic here
    }
  };
  const handleBack = () => {
    navigate("/userdashboard/payroll/configuration/PayrollPayHead/");
  };



  return (
    <Pagelayout pageName="Create Pay Head" headerLayout={2} handleBack={handleBack}  newData={true} handleSave={handleSave} > 
      <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
        <Grid container spacing={3}>
          {/* Category */}
          <Grid item xs={12} md={6}>
            <Autocomplete
              size='small'
              options={categoryOptions}
              value={formData.category}
              onChange={handleChange('category')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  error={!!errors.category}
                  helperText={errors.category}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            />
          </Grid>

          {/* Name */}
          <Grid item xs={12} md={6}>
            <TextField
              size='small'
              fullWidth
              label="Name"
              value={formData.name}
              onChange={handleChange('name')}
              error={!!errors.name}
              helperText={errors.name}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* Include in Basic Switch */}
          {/* <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={formData.includeInBasic}
                  onChange={handleChange('includeInBasic')}
                />
              }
              sx={{ mx: 1 }}
              label=" Include in Basic"
            />
          </Grid> */}

          {/* Is Loan Deduction */}
          <Grid item xs={12} md={6}>
            <Autocomplete
              size='small'
              options={['YES', 'NO']}
              value={formData.isLoanDeduction}
              onChange={handleChange('isLoanDeduction')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Is Loan Deduction"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            />
          </Grid>

          {/* Calculation Type */}
          <Grid item xs={12} md={6}>
            <Autocomplete
              size='small'
              options={calculationTypeOptions}
              value={formData.calculationType}
              onChange={handleChange('calculationType')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Calculation Type"
                  error={!!errors.calculationType}
                  helperText={errors.calculationType}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            />
          </Grid>

          {/* Conditional Fields based on Calculation Type */}
          {formData.calculationType === '% OF BASIC' && (
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                fullWidth
                label="Percentage of Basic"
                value={formData.percentageOfBasic}
                onChange={handleChange('percentageOfBasic')}
                error={!!errors.percentageOfBasic}
                helperText={errors.percentageOfBasic}
                InputLabelProps={{ shrink: true }}
                type="number"
              />
            </Grid>
          )}

          {formData.calculationType === '% OF CTC' && (
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                fullWidth
                label="Percentage of CTC"
                value={formData.percentageOfCTC}
                onChange={handleChange('percentageOfCTC')}
                error={!!errors.percentageOfCTC}
                helperText={errors.percentageOfCTC}
                InputLabelProps={{ shrink: true }}
                type="number"
              />
            </Grid>
          )}

          {formData.calculationType === 'FIXED AMOUNT' && (
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                fullWidth
                label="Fixed Amount"
                value={formData.fixedAmount}
                onChange={handleChange('fixedAmount')}
                error={!!errors.fixedAmount}
                helperText={errors.fixedAmount}
                InputLabelProps={{ shrink: true }}
                type="number"
              />
            </Grid>
          )}

          {/* Expense Account */}
          <Grid item xs={12} md={6}>
            <Autocomplete
              size='small'
                options={expenseAccountOptions}
              value={formData.expenseAccount}
              onChange={handleChange('expenseAccount')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Expense Account"
                  error={!!errors.expenseAccount}
                  helperText={errors.expenseAccount}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            />
          </Grid>

          {/* Loan Account */}
          {formData.isLoanDeduction === 'YES' && (
            <Grid item xs={12} md={6}>
              <Autocomplete
                size='small'
                options={loanAccountOptions}
                value={formData.loanAccount}
                onChange={handleChange('loanAccount')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Loan Account"
                    error={!!errors.loanAccount}
                    helperText={errors.loanAccount}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        {/* Save Button */}
        {/* <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            size="large"
          >
            Save
          </Button>
        </Box> */}
      </Paper>
    </Pagelayout>
  );
};

export default CreatePayHead;