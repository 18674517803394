import React, { useState } from "react";
import Pagelayout from "../../components/Pagelayout";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Divider,
  Stack,
  Autocomplete,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Chip,
  Paper,
  styled,
} from "@mui/material";
// Styled components for custom scrollbar
const StyledTableContainer = styled(TableContainer)({
  maxHeight: "300px",
  "&::-webkit-scrollbar": {
    width: "6px",
    height: "6px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#555",
    },
  },
});

const CreateLoanPayroll = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/userdashboard/payroll/loan/loanPayroll");
  };

  const mockData = {
    employees: ["Employee 1", "Employee 2", "Employee 3"],
    paymentTypes: ["Payment Type 1", "Payment Type 2", "Payment Type 3"],
  };

  const [employeeData, setEmployeeData] = useState({
    employee: null,
    paymentType: null,
    interest: null,
    loanAmount: null,
    installmentStartDate: null,
    installmentAmount: null,
  });

  const handleEmployeeDataChange = (field, newValue) => {
    setEmployeeData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const paymentMethods = ["Bank", "Cash", "UPI"];
  const paymentTypes = ["Cheque", "NEFT", "RTGS", "Cash"];

  const renderAutoComplete = (field, label, options) => (
    <Autocomplete
      options={options}
      value={employeeData[field]}
      onChange={(_, newValue) => handleEmployeeDataChange(field, newValue)}
      isOptionEqualToValue={(option, value) =>
        option === value || (option === null && value === "")
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );

  const handleSave = () => {
    console.log(employeeData);
  };
  return (
    <Pagelayout
      newData={true}
      pageName={"Create Loan"}
      headerLayout={2}
      handleBack={handleBack}
      handleSave={handleSave}
    >
      {/* section 1 */}
      <Box>
        <Card elevation={3} sx={{ maxWidth: "100%", mx: "auto", p: 3 }}>
          <CardContent>
            <Grid container spacing={3}>
              {/* Left Column */}
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  {renderAutoComplete(
                    "employee",
                    "Employee",
                    mockData.employees
                  )}

                  <Box sx={{ bgcolor: "#f5f5f5", p: 2, borderRadius: 1 }}>
                    <Stack spacing={2}>
                      <Typography variant="body2" color="text.secondary">
                        Branch: LEEYET
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Designation: ACCOUNTANT
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Department: FINANCE
                      </Typography>
                    </Stack>
                  </Box>

                  {renderAutoComplete(
                    "paymentType",
                    "Payment Type",
                    mockData.paymentTypes
                  )}

                  <TextField
                    fullWidth
                    label="Loan Amount"
                    variant="outlined"
                    type="number"
                    value={employeeData.loanAmount}
                    onChange={(e) =>
                      handleEmployeeDataChange("loanAmount", e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Stack>
              </Grid>

              {/* Right Column */}
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Interest %"
                    variant="outlined"
                    type="number"
                    value={employeeData.interest}
                    onChange={(e) =>
                      handleEmployeeDataChange("interest", e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />

                  <Box sx={{ bgcolor: "#f5f5f5", p: 2, borderRadius: 1 }}>
                    <Stack spacing={2}>
                      <Typography variant="body2" color="text.secondary">
                        Interest Amount: 0.00
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Total: 0.00
                      </Typography>
                    </Stack>
                  </Box>

                  <TextField
                    label="Installment Start Date"
                    size="small"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={employeeData.installmentStartDate}
                    onChange={(e) =>
                      handleEmployeeDataChange(
                        "installmentStartDate",
                        e.target.value
                      )
                    }
                  />

                  <TextField
                    fullWidth
                    label="Installment Amount"
                    variant="outlined"
                    type="number"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    value={employeeData.installmentAmount}
                    onChange={(e) =>
                      handleEmployeeDataChange(
                        "installmentAmount",
                        e.target.value
                      )
                    }
                  />

                  <Box sx={{ bgcolor: "#f5f5f5", p: 2, borderRadius: 1 }}>
                    <Stack spacing={2}>
                      <Typography variant="body2" color="text.secondary">
                        Installment End Date: DD/MM/YYYY
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        No. of Installments: 00
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {/* section 2 */}
      <Box>
        <Card elevation={3} sx={{ maxWidth: "100%", mx: "auto", p: 3 }}>
          <CardContent>
            {/* Employee and Loan Amount Header */}
            <Box
              sx={{
                bgcolor: "#f8f9fa",
                p: 2,
                borderRadius: 1,
                mb: 3,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1">
                <strong>Employee:</strong> EMP054-Vaisak
              </Typography>
              <Typography variant="body1">
                <strong>Loan Amount:</strong> ₹5000.00
              </Typography>
            </Box>

            <Grid container spacing={3}>
              {/* Left Column */}
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <TextField
                    label="Date"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />

                  <Autocomplete
                    size="small"
                    options={paymentMethods}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Method"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    fullWidth
                  />

                  <Autocomplete
                    size="small"
                    options={paymentTypes}
                    renderInput={(params) => (
                      <TextField
                        I
                        {...params}
                        label="Type"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    fullWidth
                  />
                </Box>
              </Grid>

              {/* Right Column */}
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <TextField
                    label="Cheque Date"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />

                  <TextField
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="Cheque No"
                    variant="outlined"
                  />

                  <TextField
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="Transaction No"
                    variant="outlined"
                    disabled
                    sx={{ bgcolor: "#f5f5f5" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      {/* table */}
      <Box sx={{ p: { xs: 2, sm: 3 } }}>
        {/* Header Section */}
        <Box sx={{ mb: 3 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              mb: 2,
              gap: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h5">LOAN - 0001</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
                width: "100%",
                maxWidth: { sm: "auto", md: "60%" },
              }}
            >
              <Box
                sx={{
                  bgcolor: "#e8f5e9",
                  p: { xs: 1, sm: 2 },
                  borderRadius: 1,
                  flex: 1,
                  minWidth: "120px",
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  TOTAL REPAYMENT AMOUNT
                </Typography>
                <Typography variant="h6" color="success.main">
                  AED 1,000.00
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#ffebee",
                  p: { xs: 1, sm: 2 },
                  borderRadius: 1,
                  flex: 1,
                  minWidth: "120px",
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  REMAINING AMOUNT
                </Typography>
                <Typography variant="h6" color="error.main">
                  AED 4,000.00
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "#fff3e0",
                  p: { xs: 1, sm: 2 },
                  borderRadius: 1,
                  flex: 1,
                  minWidth: "120px",
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  INSTALLMENT REMAINING
                </Typography>
                <Typography variant="h6" color="warning.main">
                  04
                </Typography>
              </Box>
            </Box>
          </Box>

          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Reason: PERSONAL LOAN
          </Typography>

          {/* Loan Details Grid */}
          <Box
            sx={{
              bgcolor: "#f8f9fa",
              p: 2,
              borderRadius: 1,
              mb: 3,
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)" },
              gap: 2,
            }}
          >
            <Box>
              <Typography variant="body2" color="text.secondary">
                Employee
              </Typography>
              <Typography variant="body1">EMP001-Vaisakh</Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Employee Loan
              </Typography>
              <Typography variant="body1">AED 5,000.00</Typography>
            </Box>
          </Box>

          {/* Date Information Grid */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              gap: 2,
              mb: 3,
            }}
          >
            <Box sx={{ bgcolor: "#f8f9fa", p: 2, borderRadius: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Disbursement Date
              </Typography>
              <Typography variant="body1">04/01/2024</Typography>
            </Box>
            <Box sx={{ bgcolor: "#f8f9fa", p: 2, borderRadius: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Installment Amount
              </Typography>
              <Typography variant="body1">AED 1,000.00</Typography>
            </Box>
            <Box sx={{ bgcolor: "#f8f9fa", p: 2, borderRadius: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Next Installment Date
              </Typography>
              <Typography variant="body1">04/02/2024</Typography>
            </Box>
            <Box sx={{ bgcolor: "#f8f9fa", p: 2, borderRadius: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Loan Closing Date
              </Typography>
              <Typography variant="body1">04/06/2024</Typography>
            </Box>
          </Box>
        </Box>

        {/* Table Section */}
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", bgcolor: "#f8f9fa" }}>
                  INSTALLMENT DATE
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", bgcolor: "#f8f9fa" }}>
                  EMI
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", bgcolor: "#f8f9fa" }}>
                  Total Amount Repaid
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", bgcolor: "#f8f9fa" }}>
                  REMAINING AMOUNT
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", bgcolor: "#f8f9fa" }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>04/01/2024</TableCell>
                <TableCell>AED 1,000.00</TableCell>
                <TableCell>AED 1,000.00</TableCell>
                <TableCell>AED 4,000.00</TableCell>
                <TableCell>
                  <Chip
                    label="Approved"
                    size="small"
                    sx={{
                      bgcolor: "#e8f5e9",
                      color: "#2e7d32",
                      fontWeight: "medium",
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </Pagelayout>
  );
};

export default CreateLoanPayroll;
