import "./App.css";
import "./css/Global Css/globalCss.css";
import Dashboard from "./component/Dashboard/Dashboard";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginNew from "./component/Login/LoginNew"
import { ProtectedRoutes } from "./ProtectedRoutes";
import PosReaderPreview from "./component/Modules/PointOfSale/posReaderPreview/PosReaderPreview";

import SuccessSnackbar from "./component/Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "./component/Single Components/SnackBars/ErrorSnackbar";
import { LoadingForm } from "./component/Single Components/LoadingForm";
import { useSelector } from "react-redux";
import store from "./Redux/store";
import { setErrorSnack, setSuccessSnack } from "./Redux/Dashboard/mainDashBoardSlice";

function App() {
  const { successSnack, errorSnack, snackMsg } = useSelector(
    (state) => state.mainDashBoardSlice
  );
  const { submitLoading } = useSelector((state) => state.mainDashBoardSlice);
  
  const handleClose=()=>{
    store.dispatch(setSuccessSnack(false))
    store.dispatch(setErrorSnack(false))
  }
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route exact path="/" element={<Login />} /> */}
          <Route exact path="/" element={<LoginNew />} />
          <Route path="posReaderPreview/*" element={<PosReaderPreview />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="userdashboard/*" element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>


      <SuccessSnackbar
        open={successSnack}
        handleClose={handleClose}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnack}
        handleClose={handleClose}
        message={snackMsg}
      />
      <LoadingForm loading={submitLoading} />
    </div>
  );
}

export default App;
