import React, { useState } from "react";
import Pagelayout from "../../components/Pagelayout";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Grid, TextField } from "@mui/material";

const CreatePayOthers = () => {
  const navigate = useNavigate();
  const [payOthers, setPayOthers] = useState({
    category: "",
    name: "",
    amount: "",
    defaultAccount: "",
    isActive: true,
  });
  const [errors, setErrors] = useState({});

  // Handle change for input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayOthers((prev) => ({ ...prev, [name]: value }));

    // Clear the error for the specific field when a value is entered
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  // Handle Autocomplete change
  const handleAutocompleteChange = (event, newValue) => {
    setPayOthers((prev) => ({ ...prev, defaultAccount: newValue || "" }));

    // Clear error when a valid value is selected
    if (newValue && newValue.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, defaultAccount: "" }));
    }
  };

  // Handle back navigation
  const handleBack = () =>
    navigate("/userdashboard/payroll/configuration/PayOthersPayroll/");

  // Handle save with validation
  const handleSave = () => {
    const newErrors = {};

    // Validate all fields are filled
    if (!payOthers.category) newErrors.category = "Category is required";
    if (!payOthers.name) newErrors.name = "Name is required";
    if (!payOthers.amount) newErrors.amount = "Amount is required";
    if (!payOthers.defaultAccount)
      newErrors.defaultAccount = "Default account is required";

    setErrors(newErrors);

    // If there are no errors, proceed
    if (Object.keys(newErrors).length === 0) {
      console.log(payOthers);
    }
  };

  return (
    <Pagelayout
      pageName="Create Pay Others"
      headerLayout={2}
      handleBack={handleBack}
      handleSave={handleSave}
      newData={true}
    >
      <Grid container spacing={2}>
        {/* Category Input */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Category"
            name="category"
            size="small"
            fullWidth
            required
            value={payOthers.category}
            onChange={handleChange}
            error={!!errors.category}
            helperText={errors.category}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {/* Name Input */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Name"
            name="name"
            size="small"
            fullWidth
            required
            value={payOthers.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {/* Amount Input */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Amount"
            name="amount"
            size="small"
            fullWidth
            required
            value={payOthers.amount}
            onChange={handleChange}
            error={!!errors.amount}
            helperText={errors.amount}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {/* Default Account Autocomplete */}
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={["Salaries a/c", "Other a/c", "Bank a/c", "Cash a/c"]}
            value={payOthers.defaultAccount}
            onChange={handleAutocompleteChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Default Account"
                name="defaultAccount"
                size="small"
                fullWidth
                required
                error={!!errors.defaultAccount}
                helperText={errors.defaultAccount}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Pagelayout>
  );
};

export default CreatePayOthers;
