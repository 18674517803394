import axios from "axios";
import { HEADERS, HEADER_FORM_DATA } from "../../../../API/UrlAndPaths";
import { generate_inventory_product_code, generate_inventory_product_master__code, get_inventory_product_master_res_list, get_inventory_product_master_row_list, get_inventory_product_res_list, get_inventory_product_row_list, get_updated_tax, product_list_for_stock_report, set_product_dialog_open } from "./inventoryProductsSlice";
import store from "../../../../Redux/store";
import { getAllNewProductList } from "../../Accounts/Vendor/API/vendorBillAPI";
import { setSubmitLoading } from "../../../../Redux/Dashboard/mainDashBoardSlice";
import { errorSnackMsg, successSnackMsg } from "../../../Custom Hooks/SnackkBarUtilities";



// view inventory product master list

export const viewInventoryProductMasterListAPICall = async (body, setIsLoading, setSnackBarStates, snackBarStates) => {
    setIsLoading !== undefined && setIsLoading(true)
    await axios.post("readymade/viewproductmastermain", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_inventory_product_master_res_list({ inventoryProductMasterData: res.data }))
                setIsLoading !== undefined && setIsLoading(false)
            }
        })
        .catch((err) => {
            store.dispatch(get_inventory_product_master_res_list({ inventoryProductMasterData: undefined }))
            setIsLoading !== undefined && setIsLoading(false)

            let message = "oops!"
            switch (err.response.status) {
                case 404:
                    message = 'No data available..'
                    break;
                case 500:
                    message = 'Server error!!'
                    break;
                case 401:
                    message = 'Unauthorized!!'
                    break;
                case 403:
                    message = 'Forbidden!!'
                    break;
                default:
                    message = 'Unseen error occurred,Please contact to EYET Support!!!!'
                    break;
            }
            setSnackBarStates({ ...snackBarStates, message: message, alert: true })
        })
}
export const ProductMasterFilterAPICall = (body, setIsLoading) => {
    setIsLoading !== undefined && setIsLoading(true)
    axios
        .post("readymade/searchProductMaster", body, {
            headers: {
                'Content-Type': 'application/json',
            },

        })
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_inventory_product_master_res_list({ inventoryProductMasterData: res.data }));
                setIsLoading !== undefined && setIsLoading(false)
            }
        })
        .catch((err) => {
            store.dispatch(get_inventory_product_master_res_list({ inventoryProductMasterData: undefined }));
            setIsLoading !== undefined && setIsLoading(false)
        });
};
// create product master api call 
export const createInventoryProductMaster = async (body, postFn, setState, state, setLoadingForm) => {
    setLoadingForm !== undefined && setLoadingForm(true)

    await axios.post("readymade/addproductmaster", body, HEADER_FORM_DATA)
        .then((res) => {
            if (res.status === 200) {
                setState({ ...state, message: "Product master added", success: true })
                postFn()
                setLoadingForm !== undefined && setLoadingForm(false)
                getAllNewProductList()

            }
        })
        .catch((err) => {
            setLoadingForm !== undefined && setLoadingForm(false)
            if (err.response.status === 500) {
                setState({ ...state, message: "Internal Server error !", error: true })
            } else {
                setState({ ...state, message: err?.response?.data, error: true })
            }
        })
}

// edit inventory product master
export const editInventoryProductMasterAPICall = async (body, postFn, setState, state, setLoadingForm) => {
    setLoadingForm !== undefined && setLoadingForm(true)
    await axios.put("readymade/editproductmastermain", body, HEADER_FORM_DATA)
        .then((res) => {
            if (res.status === 200) {
                setState({ ...state, message: "Product master edited", success: true })
                postFn()
                setLoadingForm !== undefined && setLoadingForm(false)
                getAllNewProductList()
            }
        })
        .catch((err) => {
            setLoadingForm !== undefined && setLoadingForm(false)

            if (err.response.status === 500) {
                setState({ ...state, message: "Internal Server error !", error: true })
            } else {
                setState({ ...state, message: err?.response?.data, error: true })
            }
        })
}

//  generate product master code
export const generateProductMasterCodeAPICall = async () => {
    await axios.post("readymade/generateprodmasterid", {}, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(generate_inventory_product_master__code({ inventoryProductMasterCode: res.data }))
            }
        })
        .catch((err) => {
            console.log(err.response);
        })
}


// inventory products list

export const viewInventoryProductListAPICall = async (body, setIsLoading, setSnackBarStates, snackBarStates) => {
    setIsLoading !== undefined && setIsLoading(true)
    await axios.post("readymade/viewproductvarientsmain", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_inventory_product_res_list({ inventoryProductData: res.data }))
                setIsLoading !== undefined && setIsLoading(false)
            }
        })
        .catch((err) => {
            store.dispatch(get_inventory_product_res_list({ inventoryProductData: undefined }))
            setIsLoading !== undefined && setIsLoading(false)

            let message = "oops!"
            switch (err.response.status) {
                case 404:
                    message = 'No data available..'
                    break;
                case 500:
                    message = 'Server error!!'
                    break;
                case 401:
                    message = 'Unauthorized!!'
                    break;
                case 403:
                    message = 'Forbidden!!'
                    break;
                default:
                    message = 'Unseen error occurred,Please contact to EYET Support!!!!'
                    break;
            }
            setSnackBarStates({ ...snackBarStates, message: message, alert: true })
        })
}
export const ProductListUpdatedFilterAPICall = (body, setIsLoading) => {
    setIsLoading !== undefined && setIsLoading(true)
    axios.post("readymade/searchProduct", body, {
        headers: {
            'Content-Type': 'application/json',
        },

    })
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_inventory_product_res_list({ inventoryProductData: res.data }))
                setIsLoading !== undefined && setIsLoading(false)
            }
        })
        .catch((err) => {
            store.dispatch(get_inventory_product_res_list({ inventoryProductData: undefined }))
            setIsLoading !== undefined && setIsLoading(false)
        })

}
// create product master api call  
export const createInventoryProduct = async (body, postFn, setState, state, setLoadingForm, setAddProductDialog, productPopupLoading) => {
    setLoadingForm !== undefined && setLoadingForm(true)

    await axios.post("readymade/addproductsub", body, HEADER_FORM_DATA)
        .then((res) => {
            if (res.status === 200) {
                successSnackMsg("Product added successfully")
                postFn()
                setLoadingForm !== undefined && setLoadingForm(false)
                setLoadingForm(false)
                
                getAllNewProductList(productPopupLoading)
                
                setAddProductDialog(false)
            }
        })
        .catch((err) => {
            setLoadingForm !== undefined && setLoadingForm(false)

            if (err.response.status === 500) {
                setState({ ...state, message: "Internal Server error !", error: true })
            } else {
                setState({ ...state, message: err?.response?.data, error: true })
            }
        })
}

// // edit inventory product master
export const editInventoryProductAPICall = async (body, postFn, setState, state, setLoadingForm) => {
    setLoadingForm !== undefined && setLoadingForm(true)
    await axios.put("readymade/editproductsub", body, HEADER_FORM_DATA)
        .then((res) => {
            if (res.status === 200) {
                setState({ ...state, message: "Product edited", success: true })
                postFn()
                setLoadingForm !== undefined && setLoadingForm(false)
                getAllNewProductList()

            }
        })
        .catch((err) => {
            setLoadingForm !== undefined && setLoadingForm(false)

            if (err.response.status === 500) {
                setState({ ...state, message: "Internal Server error !", error: true })
            } else {
                setState({ ...state, message: err?.response?.data, error: true })
            }
        })
}
// generate product code
export const generateProductCodeAPICall = async () => {
    await axios.post("readymade/generateprodid", {}, HEADERS)
        .then((res) => {
            store.dispatch(generate_inventory_product_code({ inventoryProductCode: res.data }))
        })
        .catch((err) => {
            console.log(err.response);
        })
}


// single view product master api call
export const singleViewProductMasterAPICall = async (body) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("readymade/viewProductMasterMainById", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch(get_inventory_product_master_row_list({ inventoryProductMasterSingleData: res.data }))
            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            store.dispatch(get_inventory_product_master_row_list({ inventoryProductMasterSingleData: undefined }))
        })
}
// single view product variant api call
export const singleViewProductVarientAPICall = async (body) => {
    store.dispatch(setSubmitLoading(true))

    await axios.post("readymade/viewProductVarientsById", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch(get_inventory_product_row_list({ inventoryProductSingleData: res.data }))
            }
        })
        .catch((err) => {
            store.dispatch(get_inventory_product_row_list({ inventoryProductSingleData: undefined }))
            store.dispatch(setSubmitLoading(false))
            console.error(err.response)
        })
}
// single view product variant api call
export const productListForStockReportAPICall = async (body) => {
    await axios.post("readymade/viewnewproductlistforstockreport", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(product_list_for_stock_report({ stockReportProducts: res.data }))
            }
        })
        .catch((err) => {
            store.dispatch(product_list_for_stock_report({ stockReportProducts: undefined }))
            console.error(err.response)
        })
}


// import and export product varient 

//   import a file
export const importProductListAPICall = async (body, setSnackBarStates, snackBarStates, closeImportPopup, setLoadingForm, setIsModify, isModify) => {
    setLoadingForm(true)
    store.dispatch(setSubmitLoading(true))
    await axios.post("readymade/importProducts", body, HEADER_FORM_DATA)

        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                setLoadingForm(false)
                // store.dispatch(get_inventory_product_res_list({ inventoryProductData: res.data }))
                setSnackBarStates({ ...snackBarStates, message: "File imported successfully", success: true })
                closeImportPopup()
                setIsModify(!isModify)
            }
        })
        .catch((error) => {
            store.dispatch(setSubmitLoading(false))
            setLoadingForm(false)
            let msg = error?.response?.status === 500 ? "Server Error" : error?.response?.data
            setSnackBarStates({ ...snackBarStates, message: msg, error: true })
        })
}

export const downloadExcelFile = (response) => {
    store.dispatch(setSubmitLoading(true))
    try {
        const fileBuffer = response.fileBuffer;
        const arrayBuffer = new Uint8Array(fileBuffer.data).buffer;

        const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response.filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        successSnackMsg("File exported")
        store.dispatch(setSubmitLoading(false))

    } catch (error) {
        store.dispatch(setSubmitLoading(false))
        errorSnackMsg("Error downloading Excel file")
    }
};

// api to export the list
export const exportProductListAPICall = async (body, setSnackBarStates, snackBarStates) => {
    try {
        const response = await axios.post("readymade/exportProducts", body, HEADERS);

        if (response.status === 200) {
            downloadExcelFile(response.data);
        } else {
            // Handle error
            console.error('Failed to download the file.');
        }
    } catch (error) {
        console.error('Export API Error:', error);
        setSnackBarStates({ ...snackBarStates, message: 'Export API Error', error: true });
    }

};

export const deleteProductAPICall = async (body) => {
    await axios.post("readymade/deleteProduct", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                //   setstate((prevState)=>({...prevState,success:true,message:"product Deleted"}))
                //   viewInventoryProductListAPICall()
                ProductListUpdatedFilterAPICall()
            }
        })
        .catch((err) => {
            console.error(err.response);
            // setstate((prevState)=>({...prevState,error:true,message:err?.response?.data}))
        })
}

export const deleteProductMasterAPICall = async (body, setstate) => {
    await axios.post("readymade/deleteProductMaster", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                // viewInventoryProductMasterListAPICall()
                setstate((prevState) => ({ ...prevState, success: true, message: "product Deleted" }))
            }
        })
        .catch((err) => {
            console.error(err.response);
            setstate((prevState) => ({ ...prevState, error: true, message: err.response.status === 500 ? "server error" : err?.response?.data }))
        })
}
export const AddTaxPopupApiCall = (body) => {

    axios.post("readymade/editMultipleProductTax", body, {

        headers: {
            'Content-Type': 'application/json',
        },

    })
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_updated_tax({ updatedTax: res.data }))

            }
        })
        .catch((err) => {
            store.dispatch(get_updated_tax({ updatedTax: undefined }))

        })

}