import React, { useState } from 'react';
import Pagelayout from '../../components/Pagelayout';
import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Paper,
  Typography,
  Chip,
  Stack,
  Autocomplete,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreateEoss = () => {
  const [formData, setFormData] = useState({
    employeeId: '',
    date: '',
    designation: '',
    department: '',
    joiningDate: '',
    endDate: '',
    eosType: 'Termination',
    calculationBase: 'Allowances Include',
    includedAllowances: [],
    noOfDays: '',
    deductLoan: 'FALSE',
    serviceYears: '01',
    unsettledLoan: '0',
    eosSettlement: '0',
    eosTotal: '0',
    netEosSettlement: '0',
    basicSalary: '26000.00',
    allowances: '10000.00'

  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate()

  const validateForm = () => {
    let tempErrors = {};
    Object.keys(formData).forEach(key => {
      if (!formData[key] && formData[key] !== 0) {
        tempErrors[key] = 'This field is required';
      }
    });
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted:', formData);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when field is filled
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  // Options for dropdowns
  const eosTypeOptions = ['Termination', 'Resignation', 'Retirement'];
  const calculationBaseOptions = ['Allowances Include', 'Basic Only'];
  const allowanceOptions = ['Food Allowance', 'TL Allowance', 'Housing Allowance'];
  const deductLoanOptions = ['TRUE', 'FALSE'];
  const employeeOptions = ['EMP120-VAISHAK', 'EMP121-JOHN', 'EMP122-JANE'];
  const designationOptions = ['IT', 'HR', 'Finance', 'Marketing'];
  const departmentOptions = ['IT Department', 'HR Department', 'Finance Department', 'Marketing Department'];

  const handleBackBtn = () =>{
    navigate("/userdashboard/payroll/attendance/EossPayroll")
  }

  return (
    <Pagelayout handleBack={handleBackBtn} pageName="Create EOSS" headerLayout={2} newData={true} handleSave={handleSubmit}>
      <Paper sx={{ p: 3 }}>
        <Typography sx={{mb:3}} variant="h6" gutterBottom>
          EOS/2023/10/0001
        </Typography>
        
        <Grid container spacing={3}>
          {/* Basic Information */}
          <Grid item xs={12} md={6}>
            <Autocomplete
              size="small"
              options={employeeOptions}
              value={formData.employeeId}
              onChange={(_, newValue) => {
                handleChange({ target: { name: 'employeeId', value: newValue } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Employee"
                  error={!!errors.employeeId}
                  helperText={errors.employeeId}
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              label="Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* Employee Details Section */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}>
              Employee Details
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              size="small"
              options={designationOptions}
              value={formData.designation}
              onChange={(_, newValue) => {
                handleChange({ target: { name: 'designation', value: newValue } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Designation"
                  error={!!errors.designation}
                  helperText={errors.designation}
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              size="small"
              options={departmentOptions}
              value={formData.department}
              onChange={(_, newValue) => {
                handleChange({ target: { name: 'department', value: newValue } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  error={!!errors.department}
                  helperText={errors.department}
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Joining Date"
              name="joiningDate"
              type="date"
              size="small"
              value={formData.joiningDate}
              onChange={handleChange}
                InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="End Date"
              name="endDate"
              type="date"
              size="small"
              value={formData.endDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* End of Service Details */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}>
              End of Service Details
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              size="small"
              options={eosTypeOptions}
              value={formData.eosType}
              onChange={(_, newValue) => {
                handleChange({ target: { name: 'eosType', value: newValue } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="EOS Type"
                  error={!!errors.eosType}
                  helperText={errors.eosType}
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              size="small"
              options={calculationBaseOptions}
              value={formData.calculationBase}
              onChange={(_, newValue) => {
                handleChange({ target: { name: 'calculationBase', value: newValue } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Calculation Base"
                  error={!!errors.calculationBase}
                  helperText={errors.calculationBase}
                  required
                  InputLabelProps={{ shrink: true }}
                    />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              size="small"
              multiple
              options={allowanceOptions}
              value={formData.includedAllowances}
              onChange={(_, newValue) => {
                handleChange({ target: { name: 'includedAllowances', value: newValue } });
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Included Allowances"
                  error={!!errors.includedAllowances}
                  helperText={errors.includedAllowances}
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Service Years"
              name="serviceYears"
              value={formData.serviceYears}
              onChange={handleChange}
              error={!!errors.serviceYears}
              helperText={errors.serviceYears}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              size="small"
              options={deductLoanOptions}
              value={formData.deductLoan}
              onChange={(_, newValue) => {
                handleChange({ target: { name: 'deductLoan', value: newValue } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Deduct Loan"
                  error={!!errors.deductLoan}
                  helperText={errors.deductLoan}
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Unsettled Loan"
              name="unsettledLoan"
              value={formData.unsettledLoan}
              onChange={handleChange}
              error={!!errors.unsettledLoan}
              helperText={errors.unsettledLoan}
              required
                InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="EOS Settlement/Year"
              name="eosSettlement"
              value={formData.eosSettlement}
              onChange={handleChange}
              error={!!errors.eosSettlement}
              helperText={errors.eosSettlement}
              required
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
              }}
            />
          </Grid>

          {/* Financial Details */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Basic Salary"
              name="basicSalary"
              value={formData.basicSalary}
              onChange={handleChange}
              error={!!errors.basicSalary}
              helperText={errors.basicSalary}
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Allowances"
              name="allowances"
              value={formData.allowances}
              onChange={handleChange}
              error={!!errors.allowances}
              helperText={errors.allowances}
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
              }}
            />
          </Grid>

          {/* Settlement Details */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="EOS Total"
              name="eosTotal"
              value={formData.eosTotal}
              onChange={handleChange}
              error={!!errors.eosTotal}
              helperText={errors.eosTotal}
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Net EOS Settlement"
              name="netEosSettlement"
              value={formData.netEosSettlement}
              onChange={handleChange}
              error={!!errors.netEosSettlement}
              helperText={errors.netEosSettlement}
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
              }}
            />
          </Grid>
        </Grid>
        <Grid sx={{mt:3}}  >
            <Typography sx={{mr:2,fontWeight:"bold",color:"green"}}>Basic Salary : {formData.basicSalary}</Typography>
            <Typography sx={{mr:2,fontWeight:"bold",color:"blue"}}>Allowances : {formData.allowances}</Typography>
          
        </Grid>
      </Paper>
    </Pagelayout>
  );
};

export default CreateEoss;